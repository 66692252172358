<template>
  <Login @submitted="login" :disabled="disabled" />
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import Login from '@/components/Login'
import { useToast } from 'primevue/usetoast.js'

export default {
  name: 'Home',
  components: {
    Login
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const disabled = ref(false)

    function login(formData) {
      disabled.value = true

      store
        .dispatch('login', formData)
        .then(() => {
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Login Success',
            life: 3000
          })
        })
        .catch(err => {
          console.log(err)
          disabled.value = false
          toast.add({
            severity: 'error',
            summary: 'Login Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    return {
      toast,
      login,
      disabled
    }
  }
}
</script>
