<template>
  <div class="p-d-flex p-jc-center">
    <form v-if="!reset" @submit.prevent="login">
      <div class="p-field">
        <InputText
          type="text"
          v-model="formData.email"
          placeholder="Email"
          autocomplete="email"
          id="email"
          :class="{ 'p-invalid': validation.formData.email.$error }"
          @blur="validation.formData.email.$touch()"
        />
        <BaseValidationErrors
          :field="validation.formData.email"
          :rules="['email', 'required']"
        />
      </div>
      <div class="p-field">
        <InputText
          type="password"
          v-model="formData.password"
          placeholder="Password"
          autocomplete="current-password"
          id="password"
          :class="{ 'p-invalid': validation.formData.password.$error }"
          @blur="validation.formData.password.$touch()"
        />
        <BaseValidationErrors
          :field="validation.formData.password"
          :rules="['minLength', 'required']"
        />
      </div>
      <div class="p-field">
        <a href="#" class="link" @click="reset = true">Forgot your password?</a>
      </div>
      <Button
        label="Login"
        type="submit"
        class="p-mr-3"
        id="submit"
        :disabled="validation.$invalid || disabled"
      />
    </form>
    <form v-else @submit.prevent="resetPassword">
      <div class="p-field">
        <InputText
          type="text"
          v-model="resetEmail"
          placeholder="Email"
          autocomplete="email"
          id="email"
          :class="{ 'p-invalid': resetValidation.resetEmail.$error }"
          @blur="resetValidation.resetEmail.$touch()"
        />
        <BaseValidationErrors
          :field="resetValidation.resetEmail"
          :rules="['email', 'required']"
        />
      </div>
      <Button
        label="Reset Password"
        type="submit"
        class="p-mr-3"
        id="reset"
        :disabled="resetValidation.$invalid || disabled"
      />
      <Button
        label="Cancel"
        type="button"
        class="p-mr-3 p-button-secondary"
        id="cancel"
        @click="reset = false"
      />
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { auth } from '@/services/firebase'
import { useToast } from 'primevue/usetoast.js'

export default {
  name: 'Login',
  components: {
    Button,
    InputText,
    BaseValidationErrors
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      email: null,
      password: null
    })
    const store = useStore()
    const router = useRouter()
    const toast = useToast()
    const reset = ref(false)
    const resetEmail = ref('')

    // Validation Logics
    const rules = {
      formData: {
        email: { required, email },
        password: { required, minLength: minLength(6) }
      }
    }
    const validation = useVuelidate(rules, { formData })
    const resetValidation = useVuelidate(
      { resetEmail: { required, email } },
      { resetEmail }
    )

    function login() {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value)
    }

    function resetPassword() {
      if (resetEmail.value)
        auth
          .sendPasswordResetEmail(resetEmail.value)
          .then(function () {
            // Email sent.
            reset.value = false
            toast.add({
              severity: 'success',
              summary: 'Password reset instructions sent',
              detail: 'Check your email',
              life: 3000
            })
          })
          .catch(function (error) {
            // An error happened.
            toast.add({
              severity: 'error',
              summary: 'Login Failed',
              detail: error.message,
              life: 3000
            })
          })
    }

    return {
      formData,
      store,
      router,
      validation,
      login,
      reset,
      resetEmail,
      resetValidation,
      resetPassword
    }
  }
}

/* references:
 * https://www.smashingmagazine.com/2020/05/vue-survey-app-firebase-authentication-database/
 * https://itnext.io/compose-validation-logics-with-vue-3-0-and-vuelidate-20416d734295
 */
</script>

<style scoped>
.link {
  color: #2196f3;
}
</style>
